/* You can add global styles to this file, and also import other style files */
// Custom Theming for Angular Material
// For more information: https://material.angular.io/guide/theming
@use 'sass:map';
@use '@angular/material' as material;
@include material.elevation-classes();
@include material.app-background();


/* You can add global styles to this file, and also import other style files */
$primary-color: #171347;
$accent-color: #3c3c70;
$warn-color: #ffbe3d;
$danger-color: #dc3545;
$success-color: #5cc9a7;
$info-color: #50b5ff;
$dark-color: #0d0b29;
$light-text: #ffffff;
$dark-text: rgba(#000000, 0.87);

$mat-primary: (
  main: $primary-color,
  lighter: #b9b8c8,
  darker: #0d0b2f,
  200: $primary-color,
  contrast : (
    main: $light-text,
    lighter: $dark-text,
    darker: $light-text,
  )
);
$theme-primary: material.m2-define-palette($mat-primary, main, lighter, darker);

$mat-accent: (
  main: $warn-color,
  lighter: #ffecc5,
  darker: #ffa727,
  200: $warn-color, // For slide toggle,
  contrast : (
    main: $dark-text,
    lighter: $dark-text,
    darker: $dark-text,
  )
);
$theme-accent: material.m2-define-palette($mat-accent, main, lighter, darker);

$theme-warn: material.m2-define-palette(material.$m2-red-palette, A400, 500, 900);

$theme: material.m2-define-light-theme((
  color: (
    primary: $theme-primary,
    accent: $theme-accent,
    warn: $theme-warn,
  ),
  typography: material.m2-define-typography-config(),
  density: 0,
));


@include material.all-component-themes($theme);

mat-form-field {
  @include material.form-field-density(-2);
}
.facebook-button-theme {
  $facebook-blue: (
    light: #6ba5ff, regular: #1877f2, dark: #004cbe,
    contrast: (light: black, regular: white, dark: white)
  );

  $facebook-blue2: (
    light: #7694e4, regular: #4267b2, dark: #003d82,
    contrast: (light: black, regular: white, dark: white)
  );

  $fb-primary-colors: material.m2-define-palette($facebook-blue, regular, light, dark);
  $fb-warn-colors: material.m2-define-palette($facebook-blue2, regular, light, dark);
  $alternate-button-theme: material.m2-define-light-theme(
      $fb-primary-colors, $fb-warn-colors, $theme-warn
  );

  //@include mat-button-theme($alternate-button-theme);
}

//@import "app/shared/scss/am-components/input-text";

*,
*::before,
*::after
{
  box-sizing: border-box;
}

html
{
  //font-family: sans-serif;
  //line-height: 1.15;
  //color: #0F1419 !important;
  //-webkit-text-size-adjust: 100%;
  //-webkit-tap-highlight-color: rgba(31, 45, 61, 0);

}

html, body { height: 100%; }

body
{
  font-family: "Apercu", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen-Sans, Ubuntu, Cantarell, "Helvetica Neue", sans-serif;
  font-size: 1rem;
  -webkit-text-size-adjust: 100%;
  font-weight: 300;
  line-height: 1.7;
  margin: 0;
  text-align: left;
  color: #0F1419;
  background-color: #fff;


}
h1, h2, h3, h4, h5, h6, .h1, .h2, .h3, .h4, .h5, .h6 {
  font-weight: 300 !important;
}
audio, canvas, iframe, img, svg, video {
  //vertical-align: middle;
}

a {
  background-color: transparent;
  text-decoration: none !important;
  cursor: pointer;
}

:focus {
  outline: none !important;
}

mat-drawer-content {
  font-size: 1rem;
  font-weight: 300;
  line-height: 1.7;
  margin: 0;
  text-align: left;
  color: #000;
  background-color: #fff;
  overflow: hidden !important;
}

mat-drawer-container {
  margin-bottom: -27px !important;
}

mat-drawer {
  width: 264px;
  box-shadow: 0 0 16px rgb(0 0 0 / 28%) !important;
}

.mat-mdc-input-element {
  color: #1a1a1a !important;
}

.wrapper{
  position: relative;
  padding-top: 5rem;
  padding-bottom: 4rem;
  overflow-x: hidden;
}

.color-primary {
  color: $primary-color !important;
}

.text-primary {
  color: $accent-color !important;
}

.color-warn {
  color: $warn-color !important;
}

.color-orange {
  color: #ff971a !important;
}

.bg-section-dark {
  background-color: $primary-color;
}

.zindex-100 {
  z-index: 100 !important;
}

.card {
  position: relative;
  margin-bottom: 30px;
  box-shadow: 0 0 1.25rem rgb(31 45 61 / 5%);
}

.shape-container {
  position: absolute;
  z-index: 2;
  right: 0;
  left: 0;
  overflow: hidden;
  width: 100%;
  transform: translateZ(0);
}

.shape-position-top {
  top: 0;
  margin-top: -1px;
}

.shape-orientation-inverse {
  transform: rotate(180deg);
}

.shape-line {
  min-height: 19px;
}

.shape-container svg {
  display: block;
  vertical-align: baseline;
  pointer-events: none;
  fill: #fff;
}

.nav-link {
  display: block;
  padding: .25rem 1rem;
}

.navbar-light .navbar-nav .nav-link.active {
  color: $primary-color;
}


.mat-btn-lg {
  padding: 0.5rem 1rem !important;
  font-size: 1.25rem !important;
  line-height: 1.5 !important;
}

.mat-mdc-snack-bar-container {
  max-width: 100% !important;
}

mat-error {
  display: flex !important;
  flex-direction: row;
  align-items: center;
  mat-icon {
    width: 16px !important;
    height: 16px !important;
    font-size: 16px !important;
    margin-right: 8px;
  }
}

.mat-mdc-icon-button.mat-mdc-button-base {
  padding: 5px !important;
}
.aling-text-mat-icon {
  display: inline-flex !important;
  align-items: center !important;
}

.cursor-pointer {
  cursor: pointer !important;
}

.drawer-item {
  border-bottom-width: 1px;
  border-bottom-color: #d0d1d2;
  border-bottom-style: solid;
  color: #393c41;
}

.flag {
  height: 22px;
  width: 22px;
  border-radius: 50%;
  flex-shrink: 0;
  object-fit: cover;
}

.bordered {
  border: solid 1px #757575;
  border-radius: 8px;
}

.home-container {
  padding-bottom:10px;
  padding-top:60px
}
section {

}
section.top {
  padding-top:30px
}
@media (min-width:992px) {
  home-container {
    padding-bottom:10px;
    padding-top:50px
  }
  .top {
    padding-top:40px
  }
}

.card-linked a {
  color: #0d0b2f;
}

.card-linked a:hover {
  border: 1px solid;
  color: #0056b3;
}

.xtt {
  min-height: 110px;
  max-height: 80%;
  display : flex;
  align-items: center;
  justify-content: center;
}
.mat-mdc-card-subtitle {
  color: rgba(0, 0, 0, 0);

}
@media (prefers-reduced-motion: no-preference) {
  :root {
    scroll-behavior: auto !important;
  }
}

